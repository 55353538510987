:global(html),
:global(body) {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
}

.container {
  background: linear-gradient(to bottom, #001d26, #013343);
  background-size: cover;
  background-position: 50% 50%;
  background-blend-mode: normal;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
}

.logoheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 2.5%;
  /* Use percentage for padding */
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.logoimage {
  width: 210px;
  height: 15vh;
}
.textlogo {
  font-family: "Zilla Slab";
  font-size: 2.5vw;
  /* Use vw (viewport width) for font size */
  font-style: normal;
  font-weight: 700;
  line-height: 3.5vw;
  /* Use vw (viewport width) for line height */
  color: var(--storywise-white, #fff);
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  /* background-color: #1CEAA0; */
}

/* .title {} */

.titletext {
  color: #fff;
  text-align: center;
  font-family: "Zilla Slab";
  font-size: 3.7vh;
  /* Use vw (viewport width) for font size */
  font-style: normal;
  font-weight: 600;
  line-height: 5vw;
  /* Use vw (viewport width) for line height */
}

.Autor_publishers_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 5vw;
}

.Autor {
  border-color: #dddee0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  width: 36vh;
  height: 36vh;
  align-items: center;
  border: 2px solid #dddee0;
  border-radius: 20px;
  padding: 20px;
  text-decoration: none;
  color: inherit;
}

.Autor:hover {
  border-color: #1ceaa0;
  border: 2px solid #1ceaa0;
  cursor: pointer;
}

.Autor_image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Autor_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 98%;
}

.Autor_img {
  width: 18.4vh;
  /* height: 170px; */
}
.Autor_text_title {
  color: #f7f7f7;
  text-align: center;
  font-family: "Zilla Slab";
  font-size: 3vh;
  /* Use vw (viewport width) for font size */
  font-style: normal;
  font-weight: 700;
  line-height: 4vh;
}

.Autor_text_body {
  color: #f7f7f7;
  text-align: center;
  font-family: "Lato";
  font-size: 2.4vh;
  /* Use vw (viewport width) for font size */
  font-style: normal;
  font-weight: 400;
  width: 90%;
}

.publishers {
  text-decoration: none; /* Remove underline */
  border-color: #dddee0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  width: 36vh;
  height: 36vh;
  align-items: center;
  border: 2px solid #dddee0;
  border-radius: 20px;
  padding: 20px;
  text-decoration: none;
  color: inherit;
}

.publishers:hover {
  border-color: #1ceaa0;
  border: 2px solid #1ceaa0;
  cursor: pointer;
}

.publishers_image {
  display: flex;

  justify-content: center;
  align-items: center;
}

.publishers_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.publishers_text_title {
  color: #f7f7f7;
  text-align: center;
  font-family: "Zilla Slab";
  font-size: 3vh;
  /* Use vw (viewport width) for font size */
  font-style: normal;
  font-weight: 700;
  line-height: 4vh;
}

.publishers_text_body {
  color: #f7f7f7;
  text-align: center;
  font-family: "Lato";
  font-size: 2.3vh;
  /* Use vw (viewport width) for font size */
  font-style: normal;
  font-weight: 400;
  width: 88%;
}

.Autor_publishers_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 5vw;
  width: 80%;
}

/* Add this media query for mobile devices */
@media screen and (max-width: 768px) {
  .Autor_publishers_container {
    flex-direction: column;
    gap: 3vh;
  }

  /* Adjust container width for mobile */
  .internalContainer {
    margin: auto;
  }

  /* Adjust boxes width for mobile */
  .Autor,
  .publishers {
    width: 100%;
    height: auto;
    min-height: 30vh;
  }

  .body {
    margin-bottom: 40px;
  }
}
