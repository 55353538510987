

/* global.css */

@font-face {
    font-family: 'Zilla Slab';
    src: url('./fonts/Zilla_Slab/ZillaSlab-Regular.ttf') format('truetype');
  }
  
  /* Other global styles */
 @font-face {
    font-family: 'Lato';
    src: url('./fonts/Lato/Lato-Regular.ttf');
 }

 ::-webkit-scrollbar {
   width: 4px; /* Scrollbar width */
    height: 4px; /* Scrollbar height */
   background-color: #f5f5f5; }
 
 ::-webkit-scrollbar-track {
   background: #f1f1f1; /* Change the color as desired */
 }
 
 ::-webkit-scrollbar-thumb {
   background-color: #888; /* Scrollbar thumb color */
   border-radius: 8px; }
 
 ::-webkit-scrollbar-thumb:hover {
   background-color: #7f7f7f; /* Scrollbar thumb color on hover */
   border-radius: 8px; }
 